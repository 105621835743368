import { BrowserRouter as Router, Route, Routes, useLocation } from 'react-router-dom'
import store from './store';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { Provider } from 'react-redux';
import AnimatedRoutes from './hocs/routes/Routes';
import { ToastContainer } from 'react-toastify';
function App() {
  return (
    <HelmetProvider>
      <Helmet>
        <title>Asistente Virtual Culm</title>
      </Helmet>
      <Provider store={store}>
        <Router>
            <AnimatedRoutes/>
            <ToastContainer/>
        </Router>
      </Provider>
    </HelmetProvider>
  );
}

export default App;
