import React, { useState } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import Toast from "../../../components/Alert.js";
import axios from "axios";
import { useNavigate } from "react-router-dom";

function DataForm() {
	const [loading, setLoading] = useState(false);
	const navigate = useNavigate();
	const [formData, setFormData] = useState({
		doc_type: "",
		doc_path: "",
		doc_file: null,
	});
	const [metadata, setMetadata] = useState([]);

	const { doc_type, doc_path, doc_file } = formData;

	const handleChange = (e) => {
		if (e.target.name === "doc_file") {
			const file = e.target.files[0];
			if (file) {
				if (doc_type === "pdf" && file.type !== "application/pdf") {
					Toast({ tipo: "error", mensaje: "Solo se permiten archivos PDF" });
					return;
				} else if (
					doc_type === "video" &&
					!["video/mp4", "video/quicktime", "video/x-msvideo"].includes(
						file.type
					)
				) {
					Toast({
						tipo: "error",
						mensaje:
							"Solo se permiten formatos de video válidos (mp4, mov, avi)",
					});
					return;
				}
				setFormData({ ...formData, doc_file: file });
			}
		} else {
			setFormData({ ...formData, [e.target.name]: e.target.value });
		}
	};

	const handleMetadataChange = (index, e) => {
		const values = [...metadata];
		values[index][e.target.name] = e.target.value;
		setMetadata(values);
	};

	const handleAddMetadata = () => {
		setMetadata([...metadata, { key: "", value: "" }]);
	};

	const handleRemoveMetadata = (index) => {
		const values = [...metadata];
		values.splice(index, 1);
		setMetadata(values);
	};

	const isValidYoutubeUrl = (url) => {
		const pattern = /^(https?:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
		return pattern.test(url);
	};

	const isValidUrl = (url) => {
		const pattern = new RegExp(
			"^(https?:\\/\\/)?" + // protocolo
				"((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // nombre de dominio
				"((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
				"(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // puerto y path
				"(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
				"(\\#[-a-z\\d_]*)?$",
			"i"
		);
		return !!pattern.test(url);
	};

	const onSubmit = async (e) => {
		e.preventDefault();
		setLoading(true);
		if (
			!doc_type ||
			(doc_type === "pdf" && !doc_file) ||
			(doc_type === "url" && !doc_path) ||
			(doc_type === "video" && !doc_file)
		) {
			setLoading(false);
			Toast({ tipo: "error", mensaje: "Por favor complete todos los campos" });
			return;
		}

		if (doc_type === "youtube" && !isValidYoutubeUrl(doc_path)) {
			setLoading(false);
			Toast({
				tipo: "error",
				mensaje: "Por favor, ingrese una URL de YouTube válida.",
			});
			return;
		}

		if (doc_type === "url" && !isValidUrl(doc_path)) {
			setLoading(false);
			Toast({ tipo: "error", mensaje: "Por favor, ingrese una URL válida." });
			return;
		}

		const config = {
			headers: {
				"Content-Type": "multipart/form-data",
				Authorization: `JWT ${localStorage.getItem("access")}`,
			},
		};

		const dynamicFormData = new FormData();
		dynamicFormData.append("doc_type", doc_type);
		if (doc_type === "pdf" && doc_file) {
			dynamicFormData.append("doc_path", doc_file);
		} else if (doc_type === "video" && doc_file) {
			dynamicFormData.append("doc_path", doc_file);
		} else {
			dynamicFormData.append("doc_path", doc_path);
		}
		dynamicFormData.append("action", "process_document");

		metadata.forEach((meta, index) => {
			dynamicFormData.append(`metadata[${index}][key]`, meta.key);
			dynamicFormData.append(`metadata[${index}][value]`, meta.value);
		});

		try {
			const res = await axios.post(
				`${process.env.REACT_APP_API_URL}/process-document/`,
				dynamicFormData,
				config
			);
			if (res.status === 200) {
				setLoading(false);
				Toast({ tipo: "success", mensaje: "Documento cargado con éxito" });
				navigate("/data-file");
			}
		} catch (error) {
			console.log(error);
			setLoading(false);
			Toast({ tipo: "error", mensaje: "Error al cargar el documento" });
		}
	};

	return (
		<DashboardLayout>
			<h1 className="text-2xl font-bold mb-5">Cargar Data</h1>
			<div className="p-5">
				<form onSubmit={onSubmit} className="flex flex-col gap-4">
					<div className="">
						<label
							htmlFor="doc_type"
							className="label md:m-auto md:flex md:justify-center md:mr-48"
						>
							<span className="label-text">Tipo de documento</span>
						</label>
						<select
							className="select select-bordered w-full max-w-xs md:m-auto md:justify-center md:flex"
							id="doc_type"
							name="doc_type"
							value={doc_type}
							onChange={handleChange}
						>
							<option value="">Seleccione una opción</option>
							<option value="pdf">Pdf</option>
							<option value="url">Url</option>
							<option value="youtube">Youtube</option>
							<option value="video">Video</option>
						</select>
					</div>

					{doc_type === "pdf" ? (
						<div className="">
							<label
								htmlFor="doc_file"
								className="label md:m-auto md:flex md:justify-center md:mr-48"
							>
								<span className="label-text">Cargar archivo PDF</span>
							</label>
							<input
								type="file"
								className="input input-bordered w-full max-w-xs md:m-auto md:justify-center md:flex"
								id="doc_file"
								name="doc_file"
								onChange={handleChange}
								accept="application/pdf"
							/>
						</div>
					) : doc_type === "video" ? (
						<div className="">
							<label
								htmlFor="doc_path"
								className="label md:m-auto md:flex md:justify-center md:mr-48"
							>
								<span className="label-text">Ruta del video</span>
							</label>
							<input
								type="file"
								className="input input-bordered w-full max-w-xs md:m-auto md:justify-center md:flex"
								id="doc_file"
								name="doc_file"
								onChange={handleChange}
								accept="video/*"
							/>
						</div>
					) : (
						<div className="">
							<label
								htmlFor="doc_path"
								className="label md:m-auto md:flex md:justify-center md:mr-48"
							>
								<span className="label-text">Ruta del documento</span>
							</label>
							<input
								type="text"
								className="input input-bordered w-full max-w-xs md:m-auto md:justify-center md:flex"
								id="doc_path"
								name="doc_path"
								value={doc_path}
								onChange={handleChange}
								placeholder="Escribe aquí"
							/>
						</div>
					)}

					<div className="">
						<h3 className="text-xl font-bold mb-3">Metadatos</h3>
						{metadata.map((meta, index) => (
							<div key={index} className="flex gap-2 mb-2">
								<input
									type="text"
									className="input input-bordered w-1/2"
									name="key"
									placeholder="Clave"
									value={meta.key}
									onChange={(e) => handleMetadataChange(index, e)}
								/>
								<input
									type="text"
									className="input input-bordered w-1/2"
									name="value"
									placeholder="Valor"
									value={meta.value}
									onChange={(e) => handleMetadataChange(index, e)}
								/>
								<button
									type="button"
									className="btn btn-error"
									onClick={() => handleRemoveMetadata(index)}
								>
									Eliminar
								</button>
							</div>
						))}
						<button
							type="button"
							className="btn btn-secondary"
							onClick={handleAddMetadata}
						>
							Agregar Metadato
						</button>
					</div>

				<button
					type="submit"
					className={`btn ${
						loading ? "loading" : ""
					} btn-primary bg-[#F69100] hover:bg-[#F69100] text-white border-[#F69100] hover:border-[#7900AC] md:w-80 md:justify-center md:m-auto`}
				>
					{loading ? "Cargando..." : "Cargar"}
				</button>
			</form>
		</div>
	</DashboardLayout>
);

}


export default DataForm;