import React, { useEffect } from "react";
import DashboardLayout from "../../../hocs/layout/DashboardLayout";
import { Link } from "react-router-dom";
import { Meta } from "../../../components/navigation/Helmet";

import { getData, getDataPage } from "../../../redux/actions/datos/data";
import { connect } from "react-redux";
import SmallSetPagination from "../../../components/pagination/SmallSetPagination";
function Data({ getData, data, count }) {
	useEffect(() => {
		getData({ document: true });
	}, [getData]);
	return (
		<DashboardLayout>
			<Meta title="Data Online" />
			<div className="py-5">
				<div className="">
					<h1 className="text-2xl font-medium leading-6 text-gray-900">Data</h1>
					<div className="flex justify-end">
						<Link to="/form-data-file">
							<button className="btn bg-[#F69100] text-white">Carga Data</button>
						</Link>
					</div>
					<div className="overflow-x-auto">
						<table className="table">
							<thead>
								<tr>
									<th>#</th>
									<th>Tipo de Archivo</th>
									<th>Creación</th>
									<th>Link</th>
								</tr>
							</thead>
							<tbody>
								{data.map((item, index) => (
									<tr key={index}>
										<th>{index + 1}</th>
										<td>{item.doc_type}</td>
										<td>{new Date(item.created_at).toLocaleString('es-ES', { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric', second: 'numeric' })}</td>
										<td>{item.doc_path?item.doc_path.substring(0, 50):null}</td>
									</tr>
								))}
							</tbody>
						</table>
					</div>
				</div>
			</div>
			<SmallSetPagination
        list_page={getDataPage}
        list={data}
        count={count}
      />
		</DashboardLayout>
	);
}

const mapStateToProps = (state) => ({
	data: state.data.data, // Ensure this path matches your actual state structure
	count: state.data.count,
});

export default connect(mapStateToProps, { getData })(Data);
