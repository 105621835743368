import { connect } from "react-redux";
import { useEffect, useState } from "react";
import {
  check_authenticated,
  load_user,
  login,
  refresh,
} from "../redux/actions/auth/auth";
import { Link, Navigate } from "react-router-dom";
import img_login from "../assets/img/img-login.jpg";
import img_logo from "../assets/img/cf-logo.svg";

import Toast from "../components/Alert";
import { Helmet } from "react-helmet-async";
import { Meta } from "../components/navigation/Helmet";

function Login({
  login,
  isAuthenticated,
  loading,
  refresh,
  check_authenticated,
  load_user,
}) {
  useEffect(() => {
    isAuthenticated ? (
      <></>
    ) : (
      <>
        {refresh()}
        {check_authenticated()}
        {load_user()}
      </>
    );
  }, [isAuthenticated,	refresh, check_authenticated, load_user]);

  const [formData, setFormData] = useState({
    email: "",
    password: "",
  });

  const { email, password } = formData;

  const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });

  const onSubmit = (e) => {
    e.preventDefault();
    login(email, password);
  };

  // const loginWithGoogle = async () => {
  //   try {
  //     const res = await axios.get(
  //       `${process.env.REACT_APP_API_URL}/auth/o/google-oauth2/?redirect_uri=${process.env.REACT_APP_API_URL}/dashboard`
  //     );
  //     window.location.replace(res.data.authorization_url);
  //   } catch (err) {
  //     Toast({ tipo: "error", mensaje: "Error al iniciar sesion con Google" });
  //   }
  // };

  if (isAuthenticated) {
    // Redireccionar al dashboard y mostrar la alerta de éxito
    const redirectToDashboard = () => {
      Toast({ tipo: "success", mensaje: "Has Iniciado Sesion!" });
      return <Navigate to="/dashboard" />;
    };

    // Renderizar la redirección y la alerta
    return redirectToDashboard();
  }
  return (
    <>
      <Meta title="Login" />
      <main className="h-screen py-5 lg:py-0 lg:flex lg:items-center overflow-y-scroll bg-white">
        <div className="container mx-auto w-10/12 md:w-auto">
          <div className="flex justify-center">
            <div className="card lg:card-side bg-base-100 shadow-xl">
              <figure>
                <img
                  src={img_login}
                  className="w-[320px] lg:w-auto lg:h-[540px] rounded-l-lg"
                  alt="login image"
                />
              </figure>
              <div className="card-body w-80 px-0 py-4">
                <div className="top flex justify-between lg:flex-col">
                  <div className="bg-[#F69100] pl-8 pr-3 pb-1 lg:w-36 rounded-e-full self-center lg:self-start text-white font-bold">
                    Bienvenido
                  </div>
                  <div className="flex md:justify-center lg:mt-3 pr-5 lg:pr-0">
                    <img className="w-24 md:w-32" src={img_logo} alt="Logo" />
                  </div>
                </div>
                <div className=" px-8">
                  <div className="">
                    <h2 className="text-center text-gray-900">
                      Ingresa a tu cuenta
                    </h2>
                    {/* <div className="block">
                      <a
                        onClick={loginWithGoogle}
                        className="flex gap-3 rounded-md border border-transparent bg-red-600 py-2 px-4 text-sm font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2"
                      >
                        <span className="flex items-center">
                          <IconLock
                            className="h-5 w-5 text-white group-hover:text-indigo-400"
                            aria-hidden="true"
                          />
                        </span>
                        Ingresar con Google
                      </a>
                    </div> */}
                  </div>
                  <form
                    onSubmit={(e) => {
                      onSubmit(e);
                    }}
                    className="mt-2 lg:mt-4"
                    action="#"
                    method="POST"
                  >
                    <input type="hidden" name="remember" defaultValue="true" />
                    <div className="">
                      <div className="mb-2">
                        <label htmlFor="email-address" className="">
                          Correo
                        </label>
                        <input
                          id="email-address"
                          name="email"
                          value={email}
                          onChange={(e) => onChange(e)}
                          type="email"
                          required
                          className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
                        />
                      </div>
                      <div>
                        <label htmlFor="password" className="">
                          Contraseña
                        </label>
                        <input
                          id="password"
                          name="password"
                          value={password}
                          onChange={(e) => onChange(e)}
                          type="password"
                          required
                          className="w-full rounded-lg px-3 py-2 text-gray-900 bg-gray-200 focus:z-10 sm:text-sm"
                        />
                      </div>
                    </div>

                    <div className="mt-6 lg:mt-8 flex justify-center">
                      <button
                        type="submit"
                        className="rounded-lg border border-transparent bg-[#7900AC] px-6 py-2 text-sm text-white"
                      >
                        Ingresar
                      </button>
                    </div>
                    {/* <div className="mt-6 text-center text-sm">
                      <a href="#" className="text-[#7900AC]">
                        Crear usuario
                      </a>
                    </div>
                    <div className="mt-4 lg:mt-12 text-center text-sm">
                      <Link
                        to="/forgot_password"
                        className="text-[#7900AC] border-b border-b-[#7900AC] pb-1"
                      >
                        ¿Olvidó su contraseña?
                      </Link>
                    </div> */}
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

const mapStateToProps = (state) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loading: state.auth.loading,
});

export default connect(mapStateToProps, {
  login,
  refresh,
  check_authenticated,
  load_user,
})(Login);
