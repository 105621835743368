import {
  LOGIN_SUCCESS,
  LOGIN_FAIL,
  RESET_PASSWORD_SUCCESS,
  RESET_PASSWORD_FAIL,
  RESET_PASSWORD_CONFIRM_SUCCESS,
  RESET_PASSWORD_CONFIRM_FAIL,
  SET_AUTH_LOADING,
  REMOVE_AUTH_LOADING,
  LOGOUT,
  AUTHENTICATED_SUCCESS,
  AUTHENTICATED_FAIL,
  REFRESH_SUCCESS,
  REFRESH_FAIL,
  USER_LOADED_SUCCESS,
  USER_LOADED_FAIL,
  GOOGLE_AUTH_FAIL,
  GOOGLE_AUTH_SUCCESS,
} from "../actions/auth/types";

const initialState = {
  access: localStorage.getItem("access"),
  refresh: localStorage.getItem("refresh"),
  isAuthenticated: localStorage.getItem("auth"),
  user: null,
  loading: false,
  user_loading: true,
};

export default function auth(state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case USER_LOADED_SUCCESS:
      return {
        ...state,
        user: payload,
        user_loading: false,
      };
    case USER_LOADED_FAIL:
      return {
        ...state,
        user: null,
        user_loading: false,
      };
    case SET_AUTH_LOADING:
      return {
        ...state,
        loading: true,
      };
    case REMOVE_AUTH_LOADING:
      return {
        ...state,
        loading: false,
      };
    case AUTHENTICATED_SUCCESS:
      return {
        ...state,
        isAuthenticated: true,
      };
    case AUTHENTICATED_FAIL:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("auth");
      return {
        ...state,
        isAuthenticated: false,
        access: null,
        refresh: null,
      };
    case GOOGLE_AUTH_SUCCESS:
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
      localStorage.setItem("auth", true);
      return {
        ...state,
        isAuthenticated: localStorage.getItem("auth"),
        access: localStorage.getItem("access"),
        refresh: localStorage.getItem("refresh"),
      };

    case LOGIN_SUCCESS:
      localStorage.setItem("access", payload.access);
      localStorage.setItem("refresh", payload.refresh);
      localStorage.setItem("auth", true);
      return {
        ...state,
        isAuthenticated: localStorage.getItem("auth"),
        access: localStorage.getItem("access"),
        refresh: localStorage.getItem("refresh"),
      };
    case RESET_PASSWORD_SUCCESS:
    case RESET_PASSWORD_FAIL:
    case RESET_PASSWORD_CONFIRM_SUCCESS:
    case RESET_PASSWORD_CONFIRM_FAIL:
      return {
        ...state,
      };
    case REFRESH_SUCCESS:
      localStorage.setItem("access", payload.access);
      return {
        ...state,
        access: localStorage.getItem("access"),
      };
    case LOGIN_FAIL:
    case GOOGLE_AUTH_FAIL:
    case REFRESH_FAIL:
    case LOGOUT:
      localStorage.removeItem("access");
      localStorage.removeItem("refresh");
      localStorage.removeItem("auth");
			sessionStorage.removeItem("conversation_id");
			sessionStorage.removeItem("session_id");
			sessionStorage.removeItem("user");
			sessionStorage.removeItem("chatMessages");
      return {
        ...state,
        access: null,
        refresh: null,
        user: null,
      };
    default:
      return state;
  }
}
