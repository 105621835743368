import { Helmet } from "react-helmet-async";

export function Meta({title}){

	return (
		<Helmet>
		<title>{title} | BOT CULM</title>
	</Helmet>
	);

} 